@use '@work4all/assets/theme/mixins.scss';

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: var(--w4a-safe-area-bottom, 0px);
}

.content {
  flex-grow: 1;
  overflow: auto;
  border: none;
  @include mixins.mutedScroller;
}

.blueAction {
  color: var(--brand01);
}
