@use '@work4all/assets/theme/elevations.scss';
@use 'libs/assets/theme/spacing.scss';

.root {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  border-radius: spacing.$spaceXS;
  @include elevations.elevation6;
}

.color {
  color: var(--text03);
  padding: 0;
  margin: 0;
}

.flex {
  display: flex;
  gap: 0.5rem;
}
