.wrapper {
  width: 100%;
  flex-grow: 1;
  min-width: 40px;
}

.noLeftRadiuses {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.noRightRadiuses {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.noTopRadiuses {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.noBottomRadiuses {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
