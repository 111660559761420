.disabled {
  opacity: 0.5;
}

.tableRow {
  height: 40px;
  width: max-content;
}

.headerRow {
  color: var(--ui05);
  width: max-content;
  border: 1px solid var(--ui04);
}

.headerWrapper {
  overflow: visible;
  border-bottom: none;
}

.checkboxWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.tableCell {
  padding: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;

  .headerRow & {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.alignRight {
    text-align: right;
  }

  display: flex;
  align-items: center;
  button {
    margin: 3px;
  }
}

.borderVertical {
  border-right: 1px solid var(--ui04);
}

.borderHorizontal {
  border-bottom: 1px solid var(--ui04);
}

.borderHeader {
  border-top: 1px solid var(--ui05);
  border-left: 1px solid var(--ui05);
}

.borderRow {
  border-left: 1px solid var(--ui04);
}

.amount {
  color: var(--text02);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 6px;
  padding-right: 6px;
  min-height: 2.5rem;
  width: 100%;
}

.tfoot {
  padding-bottom: 0.5rem;
}
