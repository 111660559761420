@use 'libs/assets/theme/spacing.scss';

.root {
  display: flex;
  padding: spacing.$spaceXXS;
}

.spacer {
  flex: 1;
}

.resetSelectionFab {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 1;
}
