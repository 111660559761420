.pickerButton {
  cursor: pointer;
  height: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;

  border: none;
  outline: none;
  background: transparent;
  margin: -0.25rem 0 0 -0.5rem;

  &:is(.hover, :hover, :focus):not(:disabled),
  .placeholderRow &:not(:disabled) {
    background: var(--ui02);
    border: 1px solid var(--ui04);
  }

  &:disabled {
    cursor: default;
    opacity: 1;
    color: inherit;
  }
}

.pickerButtonContent {
  text-align: left;
  flex: 1;
  min-width: 0;
  min-width: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pickerButtonDropdownIcon {
  flex: none;
  display: none;
  color: var(--text02);

  :is(.pickerButton:is(.hover, :hover, :focus):not(:disabled), .placeholderRow)
    & {
    display: block;
  }
}
