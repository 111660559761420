.textInput {
  height: 100%;
  width: 100%;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;
  text-overflow: ellipsis;
  color: var(--text01);

  border: 1px solid transparent;
  outline: none;
  background: transparent;

  cursor: text;

  &:disabled {
    opacity: d;
  }

  &:is(:hover, :focus):not(:disabled),
  .placeholderRow &:not(:disabled) {
    background: var(--ui02);
    border: 1px solid var(--ui04);
  }
}

.rightTextAlign {
  text-align: right;
}
