@import 'libs/assets/theme/spacing.scss';
@import '@work4all/assets/theme/mixins.scss';

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex-grow: 1;
  overflow: auto;
  border: none;
  @include mutedScroller;
}
