.comment {
  word-break: break-word;

  a {
    word-break: break-all;
  }
}

.comment [data-mention='user'] {
  background: #d2d2d2;
  padding: 2px 8px 4px 8px;
  color: #464646;
  border-radius: 99px;
  font-family: monospace;
}

.modal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  outline: none;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Ensure clicks outside the image are detected */
}

.fullSizeImage {
  max-width: 90%;
  max-height: 90%;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
}
