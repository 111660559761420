.tableWrapper {
  overflow-x: auto;
}

.bookings {
  grid-area: bookings;
}

.summary {
  padding-top: 4px;
  padding-bottom: 4px;

  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  overflow: visible;

  .spacer {
    flex: 820 0 780px;
  }

  .label {
    color: var(--ui05);
    padding-left: 8px;
    padding-right: 8px;
    flex: 100 0 100px;
  }

  .amount {
    color: var(--text02);
    text-align: right;
    padding-left: 8px;
    padding-right: 8px;
    flex: 70 0 80px;
  }
}

.actionsWrapper {
  display: flex;
}
