.tooltip {
  max-width: 100vw;
  background-color: var(--ui03);
  color: var(--text01);
  padding: 0;
  font-weight: 400;
  font-size: 1rem;
  box-shadow: 0 2px 6px var(--ui05);

  table,
  th,
  td {
    border: 1px solid var(--ui05);
    border-collapse: collapse;
  }

  th,
  td {
    padding: 0.5rem 0.75rem;
  }

  table {
    border-radius: 0.25rem;
  }
}

.scale {
  color: var(--brand01);
}
